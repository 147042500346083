<template>
    <div>
        <p class="bg-light px-1 py-1 font-weight-bold mt-2">{{ this.trans('vue.availability' )}}</p>
        <el-button-group class="d-flex mb-2">
            <el-button class="flex-fill" type="primary" @click="today">{{ this.trans('vue.today' )}}</el-button>
            <el-button class="flex-fill" type="primary" @click="tomorrow">{{ this.trans('vue.tomorrow' )}}</el-button>
            <el-button class="flex-fill" type="primary" @click="range">{{ this.trans('vue.range' )}}</el-button>
        </el-button-group>
        <div class="block">
            <el-date-picker
              v-model="range_dates"
              type="daterange"
              align="right"
              unlink-panels
              :range-separator="$options.filters.translate('vue.to')"
              :start-placeholder="$options.filters.translate('vue.start_date')"
              :end-placeholder="$options.filters.translate('vue.end_date')"
              @change="updateRangeDates"
              :picker-options="pickerOptions"
              ref="datePicker">
            </el-date-picker>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                range_dates: [],
                pickerOptions: {
                    shortcuts: [{
                        text: this.trans('vue.today'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime());
                            picker.$emit('pick', [start, end]);
                            }
                        }, {
                        text: this.trans('vue.tomorrow'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() + 3600 * 1000 * 24 * 1);
                            end.setTime(end.getTime() + 3600 * 1000 * 24 * 1);
                            picker.$emit('pick', [start, end]);
                            }
                        }, {
                        text: this.trans('vue.after_tomorrow'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() + 3600 * 1000 * 48 * 1);
                            end.setTime(end.getTime() + 3600 * 1000 * 48 * 1);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                }
            }
        },
        methods: {
            trans(value){
                if (!value) return '';
                return  _.get(window.i18n, value);
            },
            updateRangeDates: function () {
                EventBus.$emit('update-range-date', this.range_dates);
            },
            today: function() {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime());
                this.range_dates = [start,end];
                this.updateRangeDates();
            },
            tomorrow: function() {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() + 3600 * 1000 * 24 * 1);
                end.setTime(end.getTime() + 3600 * 1000 * 24 * 1);
                this.range_dates = [start,end];
                this.updateRangeDates();
            },
            range: function() {
                this.$refs.datePicker.focus();
            }
        },
        filters: {
            translate: function (value) {
                if (!value) return '';
                return _.get(window.i18n, value);
            }
        }
    }
</script>

