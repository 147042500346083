<template>  
    <el-autocomplete
        v-model="keyword"
        :fetch-suggestions="querySearchAsync"
        :placeholder="$options.filters.translate('vue.search_placeholder')"
        size="large"
        @keyup.enter.native="handleIconClick"
        @select="handleSelect">
        <i
            class="el-icon-search el-input__icon"
            slot="suffix"
            @click="handleIconClick">
        </i>
    </el-autocomplete>
</template>
<script>
    export default {
        props: ['keywords'],
        data() {
            return {
                links: [],
                keyword: '',
                timeout:  null,
            };
        },
        methods: {
            trans(value){
                if (!value) return '';
                return  _.get(window.i18n, value);
            },
            querySearchAsync(queryString, cb) {
                var links = this.links;
                var results = queryString ? links.filter(this.createFilter(queryString)) : links;
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    cb(results);
                }, 3000 * Math.random());
            },
            createFilter(queryString) {
                return (link) => {
                    return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
            handleSelect(item) {
                window.location.replace(item.slug);
            },
            handleIconClick() {
                window.location.replace('/search?q='+this.keyword);
            },
        },
        mounted() {
             this.links = this.keywords;
        },
        filters: {
            translate: function (value) {
                if (!value) return '';
                return _.get(window.i18n, value);
            }
        }
    };
</script>
<style>
    .el-input {
        width: 100%;
    }
</style>