<template>
    <div>
        <p class="bg-light px-1 py-1 font-weight-bold mt-2">{{ this.trans('vue.price' )}}: {{this.range_price[0]}}-{{this.range_price[1]}} € </p>
        <el-slider class="px-3"
            v-model="range_price"
            range
            :step="10"
            :max="2000"
            @change="updateRangePrices">
        </el-slider>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                range_price: [0,2000],
            }
        },
        methods: {
            trans(value){
                if (!value) return '';
                return  _.get(window.i18n, value);
            },
            updateRangePrices: function () {
                EventBus.$emit('update-range-price', this.range_price);
            },
        },
        filters: {
            translate: function (value) {
                if (!value) return '';
                return _.get(window.i18n, value);
            }
        }
    }
</script>

