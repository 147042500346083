<template>
	<div class="row justify-content-center justify-content-md-start" v-loading="loading">
        <div class="col-12 text-right change-view mb-4">
            <el-button class="btn-change-view" type="primary" :disabled="currentStyle == 'horizontal' ? true : false" plain icon="fa fa-list" @click="changeProductsView('horizontal')"></el-button>
            <el-button class="btn-change-view" type="primary" :disabled="currentStyle == 'vertical' ? true : false" plain icon="fa fa-th" @click="changeProductsView('vertical')"></el-button>
        </div>
		<product v-for="producto in checkPriceDuration(products)"
			:key="producto.id"
	        :location_name="producto.location_name"
	        :product_name="producto.name"
            :product_subtittle="producto.subtittle"
            :product_description="producto.description"
            :product_image="producto.image"
	        :product_price="producto.price"
            :product_reviews="producto.reviews"
            :product_stars="producto.stars"
	        :excursions="producto.excursions"
            :product_slug="producto.slug"
            :product_duration="producto.duration"
            :product_alt="producto.alt">
		</product>
	</div>
</template>

<script>
	import Product from './Product.vue'
	export default {
        props: ['list_products', 'selected_category_id'],
        components: {
            'product': Product,
        },
        data() {
            return {
                products: [],
                range_price: [0,2000],
                range_duration: [0,16],
                currentStyle: 'horizontal',
                loading: false,
            }
        },
        methods: {
            trans(value){
                console.log(value);
                if (!value) return '';
                return  _.get(window.i18n, value);
            },
            checkPriceDuration: function (products) {
                var self = this;
                return products.filter(function (product) {
                    return  (product.price >= self.range_price[0] && product.price <= self.range_price[1]) && (product.duration >= self.range_duration[0] && product.duration <= self.range_duration[1])
                })
            },
            changeProductsView: function (new_style){
                if(this.currentStyle != new_style){

                    if(this.currentStyle =='vertical')
                        this.currentStyle = 'horizontal';
                    else
                        this.currentStyle = 'vertical';

                    EventBus.$emit('update-style', this.currentStyle);

                }
            },
            isMobile(){
                return (
                    (navigator.userAgent.match(/Android/i)) ||
                    (navigator.userAgent.match(/webOS/i)) ||
                    (navigator.userAgent.match(/iPhone/i)) ||
                    (navigator.userAgent.match(/iPod/i)) ||
                    (navigator.userAgent.match(/iPad/i)) ||
                    (navigator.userAgent.match(/BlackBerry/i))
                );
            }
        },
    	created: function() {
            if (this.selected_category_id) {
                this.loading = true;
            }
            EventBus.$on('update-products', (product_categories, date, locations, filters) => {
            	let uri = '/api/products/bycategories';
            	axios.post(uri, {
                    product_categories: product_categories,
                    filters: filters,
            		locations: locations,
            		date: date,

            	}).then((response) => {
           			this.products = response.data;
                    this.loading = false;
        		});
	        });

            EventBus.$on('update-range-price', (new_range_price) => {
                this.range_price = new_range_price;
            });

            EventBus.$on('update-range-duration', (new_range_duration) => {
                this.range_duration = new_range_duration;
            });

            EventBus.$on('product-complete', (product) => {
                if(this.isMobile()!==null){
                    this.currentStyle = 'vertical';
                    EventBus.$emit('update-style', this.currentStyle);
                }
            });
        },
	    mounted() {
            this.products = this.list_products;
	    },
        filters: {
            translate: function (value) {
                if (!value) return '';
                return _.get(window.i18n, value);
            }
        }
	}
</script>
