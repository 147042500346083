<template>
    <el-checkbox-group v-model="checkedLocations" size="large">
        <el-checkbox class="ml-0 mr-1 mb-2 pb-2 border-bottom d-block" 
            v-for="location in locations"
            :key="location.id"
            :label="location"
            :name="location"
            :checked="false"
            @change="updateLocations">{{location}}
        </el-checkbox>
    </el-checkbox-group>
</template>

<script>

    export default {
        props: ['locations'],
        data() {
            return {
                checkedLocations: [],
                checkedLocationsId: [],
            }
        },
        methods: {
            trans(value){
                console.log(value);
                if (!value) return '';
                return  _.get(window.i18n, value);
            },
            updateLocations: function () {
                this.checkedLocationsId = [];
                var arrayLength = this.checkedLocations.length;
                var location_name = '';
                for (var i = 0; i < arrayLength; i++) {
                    location_name = this.checkedLocations[i];
                    for (var key in this.locations) {
                        if (this.locations.hasOwnProperty(key))
                            if(this.locations[key] == location_name)
                                if(this.checkedLocations.indexOf(location_name)>-1)
                                    this.checkedLocationsId.push(key);
                    }
                }
                EventBus.$emit('update-product-locations', this.checkedLocationsId);
            },
        },
        filters: {
            translate: function (value) {
                if (!value) return '';
                return _.get(window.i18n, value);
            }
        }
    }
</script>

