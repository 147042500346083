<template>
    <el-container style="border: 1px solid #eee">
        <el-main style="background-color: white; overflow:hidden;" class="py-0 px-0">
            <range-dates></range-dates>
            <range-prices></range-prices>
            <range-durations></range-durations>
            <!--
            <p class="bg-light px-1 py-1 font-weight-bold mt-2">{{ this.trans('vue.cities' )}}</p>
            <cities :locations="locations"></cities>
            -->
            <el-collapse v-model="activeName">
                <el-collapse-item :title="$options.filters.translate('vue.product_categories')" name="1">
                    <categories :product_categories="product_categories"></categories>
                </el-collapse-item>
            </el-collapse>
            <el-collapse v-model="activeName">
                <el-collapse-item :title="$options.filters.translate('vue.filters')" name="1">
                    <categories :product_categories="filters"></categories>
                </el-collapse-item>
            </el-collapse>
        </el-main>
    </el-container>
</template>

<script>
    import Locations from '../Locations/Locations.vue'
    import ProductCategories from '../ProducCategories/ProductCategories.vue'
    import RangePrices from '../RangePrices/RangePrices.vue'
    import RangeDurations from '../RangeDurations/RangeDurations.vue'
    import RangeDates from '../RangeDates/RangeDates.vue'

    export default {
        props: ['product_categories', 'locations', 'filters'],
        components: {
            'categories': ProductCategories,
            'cities': Locations,
            'range-prices': RangePrices,
            'range-durations': RangeDurations,
            'range-dates': RangeDates,
        },
        data() {
            return {
                checkedProductCategoriesId: [],
                checkedLocationsId: [],
                range_price: [0,200],
                range_duration: [1,16],
                range_dates: [],
                activeName: '1',
            }
        },
        methods: {
            trans(value){
                if (!value) return '';
                return  _.get(window.i18n, value);
            },
            updateProducts: function () {
                EventBus.$emit('update-products', this.checkedProductCategoriesId, this.range_dates, this.checkedLocationsId);
            },
        },
        created: function() {
            EventBus.$on('update-product-locations', (checked_locations) => {
                this.checkedLocationsId = checked_locations;
                this.updateProducts();
            });

            EventBus.$on('update-product-categories', (checked_product_categories) => {
                this.checkedProductCategoriesId = checked_product_categories;
                this.updateProducts();
            });

            EventBus.$on('update-range-date', (new_range_dates) => {
                this.range_dates = new_range_dates;
                this.updateProducts();
            });
        },
        filters: {
            translate: function (value) {
                if (!value) return '';
                return _.get(window.i18n, value);
            }
        }
    }
</script>

