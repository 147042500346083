
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.Vue = require('vue');
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.

 */

import Vue from "vue"
import EventBus from './event-bus.js'
import FiltersSearch from './components/Search/FiltersSearch.vue'
import Products from './components/Products/Products.vue'
import Search from './components/Search/Search.vue'
import locale from 'element-ui/lib/locale/lang/en'
import Element from 'element-ui'

Vue.use(Element, { size: 'large',locale });

window.EventBus = new Vue({});

const app = new Vue({
    el: '#app',
    components: {
  		'filters-search': FiltersSearch,
  		'products': Products,
  		'search': Search,
  	},
});
